<script>
import { UserManagerService } from '@/Services/UserService';

export default {
  name: 'manage_user',
  props: {
    userData: [Object, null]
  },

  data() {return {
    isRestricted: false

  }},

  mounted()
  {
    this.RequestRestrictionStatus();
  },

  methods: {
    OpenDeleteUserConfirmation()
    {
      this.$pop.tquestion("question_title_delete_user", "question_desc_delete_user", this.DeleteUser);
    },

    DeleteUser()
    {
      UserManagerService.deleteUser(this.userData.id, (success) => {
        if (!success) 
        {
          return;
        }

        this.$router.push("/");
      });
    },


    RequestRestrictionStatus()
    {
      UserManagerService.getRestrictionStatus(this.userData.id, (restricted) => {
        this.isRestricted = restricted;
      })
    },

    OpenRestrictUserConfirmation()
    {
      this.$pop.tquestion("question_title_restrict_user", "question_desc_restrict_user", this.RestrictUser);
    },

    RestrictUser()
    {
      UserManagerService.restrictUser(this.userData.id, (success) => {
        if (!success) 
        {
          return;
        }

        this.isRestricted = true;
      });
    },

    RemoveRestriction()
    {
      UserManagerService.removeUserRestriction(this.userData.id, (success) => {
        if (!success) 
        {
          return;
        }

        this.isRestricted = false;
      });
    }
  }
};
</script>


<template>
  <div class="vertical-container">

    <div class="p-row">
      <label>
        {{ $lan("user_management_restrict_label") }}
      </label>

      <button class="button red-btn" @click="OpenRestrictUserConfirmation" v-if="!isRestricted">
        <icon>person_off</icon>
        {{ $lan("user_management_restrict_btn") }}
      </button>

      <button class="button green-btn" @click="RemoveRestriction" v-else>
        <icon>check_circle</icon>
        {{ $lan("user_management_remove_restriction_btn") }}
      </button>

    </div>


    <div class="p-row">
      <label>
        {{ $lan("user_management_delete_label") }}
      </label>

      <button class="button red-btn" @click="OpenDeleteUserConfirmation" :disabled="true">
        <icon>delete</icon>
        {{ $lan("user_management_delete_btn") }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.p-row {
  flex-direction: column;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  gap: 10px;
}

.p-row:hover {
  background-color: var(--dark-box-color);
}

.p-row > label {
  font-weight: bold;
}

.button {
  border-radius: 10px;
}
</style>